import React from "react";
import Link from "next/link";

export default function PromotionBanner({
	main_message,
	cta_message,
	has_link,
}) {
	return (
		<div className="promotion_banner">
			<p>{main_message}</p>
			{has_link && <Link href="/checkout">{cta_message}</Link>}
		</div>
	);
}
